import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import { NavBar } from "./components/NavBar";
import { Accueil } from "./components/Accueil";
// import { Footer } from "./components/Footer";
import  PrivacyPolicy from "./components/PrivacyPolicy";

const router = createBrowserRouter([
  {
    path :'/',
    element : <div>
              <Accueil/>
              </div>
  },
  {
    path :'/privacyPolicy',
    element : <div><PrivacyPolicy/></div>
  }
]);

function App() {
  return <RouterProvider router={router}/>
}

export default App;

