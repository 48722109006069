import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import headerImg from "../assets/img/header-img.svg";
import headerImg from "../assets/img/descentOfDungeon-logo.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { HashLink } from 'react-router-hash-link';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
// import {
//   BrowserRouter as Router
// } from "react-router-dom";

import { Competences } from "./Competences";
import { Projets } from "./Projets";
import { Contact } from "./Contact";


export const Accueil = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(200 - Math.random() * 80);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Rogue Like Surviror", "Action", "Shooter"];
  const period = 1600;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(50);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="accueil">
      <Container>
        <Row className="aligh-items-center pt-5 mt-2 pb-5">
          <Col xs={12} md={7} xl={8}>
            {/* Scrollabar visible ? */}
            <div className="mt-5 pt-5">
            <TrackVisibility> 
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span class="tagline">Bienvenue chez AltQuest Games</span>
                <h1 className="mt-3 ">Descent Of Dungeon</h1>
                <h3 className="mt-5"><span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Rogue Like Surviror", "Action", "Shooter"]'><span className="wrap">{text}</span></span></h3>
                {/* <h3>{`Hi ! Welcomme to AltQuest Games by BARBERI Francois`} <br/><br/> <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Rogue Like Surviror", "Action", "Shooter"]'><span className="wrap">{text}</span></span></h3> */}
                {/* <p>First game published : 'Descent Of Dungeon'</p> */}
                  {/* <Router>
                      <HashLink class="text-white" style={{textDecoration:'none'}} to='#connect'>
                        <button onClick={() => console.log('connect')}>Contact me<svg xmlns="http://www.w3.org/2000/svg" width="65.76062" height="20.94" viewBox="0 0 65.76062 20.94" creator="Katerina Limpitsouni"><path d="M3.0696,14.35003c14.77852-4.66461,30.54831-5.6899,45.76236-2.61603,4.44585,.89825,8.8161,2.1243,13.095,3.6261,1.274,.44715,2.73515-.50817,3.0753-1.7461,.37901-1.37937-.46745-2.62652-1.7461-3.0753C47.82195,5.12165,31.2588,3.6356,15.11839,6.30519c-4.53218,.74961-8.99807,1.84099-13.37799,3.22345C-1.31727,10.49374-.01125,15.32246,3.0696,14.35003h0Z" fill="#ffffff" origin="undraw"/><path d="M55.97417,3.76117l2.58581,4.86421,1.32984,2.5016,.66492,1.2508c.08649,.16269,.16913,.42327,.29552,.55591-.51452-.53997,1.42541-2.15361,.51312-1.82543-.8054,.28974-1.58804,.8423-2.34456,1.24356-.82749,.4389-1.65498,.87781-2.48247,1.31671-1.65498,.87781-3.30996,1.75562-4.96494,2.63342-1.15699,.61367-1.60767,2.31798-.89688,3.42047,.75851,1.17651,2.18291,1.55329,3.42047,.89688,1.93081-1.02411,3.86162-2.04822,5.79243-3.07233,.91943-.48767,1.83887-.97534,2.7583-1.46301,1.14784-.60882,2.34415-1.1119,2.8815-2.38522,.54904-1.30103,.0367-2.54313-.58127-3.70559l-1.55149-2.91853c-1.03432-1.94569-2.06865-3.89137-3.10297-5.83706-.61473-1.15638-2.31725-1.60814-3.42047-.89688-1.17565,.75796-1.55443,2.18355-.89688,3.42047h0Z" fill="#ffffff"/></svg> </button>
                      </HashLink>
                  </Router> */}
                
              </div>}
            </TrackVisibility>
            </div>
          </Col>
          <Col xs={12} md={5} xl={4}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
    
  )
}
