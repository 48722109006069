import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.h1}>Privacy Policy</h1>
            <p><strong>Last updated: 05/07/2024</strong></p>
            
            <h2 style={styles.h2}>About us and this privacy policy</h2>
            <p>As used in this Privacy Policy “Altquest Games” “we” “us” or “our” refers to Altquest Games. Altquest Games built “Descent Of Dungeon” as a Freemium app. This SERVICE is provided by Altquest Games at no cost and is intended for use as is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decides to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
            
            <h2 style={styles.h2}>Information Collection and Use</h2>
            <p>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy. The app does use third party services that may collect information used to identify you.</p>
            <p>Link to privacy policy of third-party service providers used by the app:</p>
            <ul>
                <li>Google Play Services</li>
                <li>Google Analytics for Firebase</li>
                <li>Unity</li>
            </ul>
            
            <h2 style={styles.h2}>Log Data</h2>
            <p>We want to inform you that whenever you use our Service, in case of an error in the app, we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
            
            <h2 style={styles.h2}>Cookies</h2>
            <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device’s internal memory.</p>
            <p>This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
            
            <h2 style={styles.h2}>Service Providers</h2>
            <p>We may employ third-party companies and individuals due to the following reasons:</p>
            <ul>
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
            
            <h2 style={styles.h2}>Security</h2>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
            
            <h2 style={styles.h2}>Children’s Privacy</h2>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers. If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
            
            <h2 style={styles.h2}>Data Deletion</h2>
            <p>The right to be forgotten or the right to erasure allows individuals (data subjects) to request from an organization (data controller) to delete their personal data. Altquest Games serves as a data controller. If you want to execute your right to be forgotten, contact us at altquestgames@gmail.com. Upon receiving a request, the organization is obligated to erase data if:</p>
            <ul>
                <li>The personal data is no longer necessary for the purposes it was collected previously;</li>
                <li>The individual withdraws consent and there is no ground for the processing of personal data;</li>
                <li>The personal data have been unlawfully processed;</li>
                <li>The individual objects to the processing that was based on legitimate interest and the organization has no grounds to continue processing;</li>
                <li>Data erasure is necessary for compliance with a legal obligation in Union or Member State law;</li>
                <li>The personal data have been collected in relation to the offer of information society services;</li>
                <li>The individual objects to the use of personal data for direct marketing purposes.</li>
            </ul>
            <p>Altquest Games will erase your data within one month from the request submission date. That deadline can be extended by up to two months in case the request proves to be more complex.</p>
            
            <h2 style={styles.h2}>Links to Other Websites</h2>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third party sites or services.</p>
            
            <h2 style={styles.h2}>Do we make updates to this notice</h2>
            <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <p>This policy is effective as of 2024-07-05.</p>
            
            <h2 style={styles.h2}>How can you contact us about this notice</h2>
            <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at altquestgames@gmail.com.</p>
        </div>
    );
}

const styles = {
    container: {
        width: '80%',
        margin: '0 auto',
        backgroundColor: '#000',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        lineHeight: '1.6'
    },
    h1: {
        fontSize: '2.5em',
        marginBottom: '20px'
    },
    h2: {
        fontSize: '2em',
        marginBottom: '15px'
    }
};

export default PrivacyPolicy;
